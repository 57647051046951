const multiSelect = (errorMsg = '') => ({
  container: (provided) => ({
    ...provided,
    marginBottom: 19,
    position: 'relative',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 50,
    backgroundColor: '#fff',
    borderRadius: 40,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
    padding: 0,
    width: '100%',
    border: errorMsg ? '1px solid red' : 'none',
    ':hover': {
      boxShadow: '0px -1px 13px rgba(114, 152, 241, 0.5)',
      borderColor: 'red',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 16,
    marginLeft: 22, // basically 28, but item has some strange 6 padding-left.
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: '15px 0 15px 0',
    borderRadius: 15,
    fontSize: 16,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12);',
    zIndex: 2,
    '::after': {
      content: '\' \'',
      position: 'absolute',
      left: 25,
      top: state.placement === 'top' ? 'auto' : '-30px',
      bottom: state.placement === 'top' ? '-30px' : 'auto',
      transform: state.placement === 'top' ? 'rotate(180deg)' : 'rotate(0deg)',
      border: '20px solid transparent',
      borderBottom: '20px solid #fff',
    },
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 99999,
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    display: 'flex !important',
    color: isFocused ? '#fff' : 'rgba(14, 20, 44, 1)',
    paddingLeft: '28px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'none',
    position: 'relative',
    maxWidth: '80%',
    margin: 0,
    padding: 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    position: 'absolute',
    right: '-10px',
    top: '-2px',
    borderRadius: '100%',
    background: '#DCDCDC',
    color: '#6E7DB4',
    margin: 0,
    padding: 0,
    ':hover': {
      color: 'red',
    },
  }),
});

export default multiSelect;
