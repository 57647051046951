import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import {
  TeamEnums,
} from 'src/enums/TeamEnums';
import { apiUrlV3, apiUrlV4 } from 'src/services/constants/api-versions';
import {
  GetSchoolsPayload,
  GetSchoolIdByDeviceSerialResData,
  AddSchoolPayload,
  GetSchoolsPaginatedPayload,
  GetSchoolsPaginatedRes,
  GetSchoolsByStatesPayload,
} from 'src/services/interfaces/Schools.types';
import { ErrorRes } from 'src/types';

export interface ImportRequest {
  school_name: string;
  email: string;
  partner_name: string;
  partner_id: string;
  message: string;
}

export const SchoolsService = (axios: AxiosInstance) => {
  const GetSchools = () => {
    const getUrl = ({ country, state, association }) => {
      const params = new URLSearchParams();

      if (country) params.append('country', country);
      if (state) params.append('state', state);
      if (association) params.append('association', association);

      if (params.toString()) {
        return `${apiUrlV4}school/state/?${params.toString()}`;
      }

      return `${apiUrlV4}school/getAll`;
    };

    return useMutation(
      'get-schools',
      ({ country, state, association }: GetSchoolsPayload) => axios
        .get(getUrl({ country, state, association }))
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get schools by state. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get schools by state:', error);
          snackbarService.error("Can't get schools by state. Please, try later.");
        }),
    );
  };

  const GetSchoolsByStates = () => {
    const getUrl = ({ country, states, association }: GetSchoolsByStatesPayload) => {
      const params = new URLSearchParams();

      if (country) params.append('country', country);
      if (states) {
        states.forEach((state) => params.append('state', state));
      }
      if (association) params.append('association', association);

      if (params.toString()) {
        return `${apiUrlV4}school/state/?${params.toString()}`;
      }

      return `${apiUrlV4}school/getAll`;
    };

    return useMutation(
      'get-schools-by-states',
      (payload: GetSchoolsByStatesPayload) => axios
        .get(getUrl(payload))
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't get schools by states. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in get schools by states:', error);
          snackbarService.error("Can't get schools by states. Please, try later.");
        }),
    );
  };

  const useGetSchools = (payload: GetSchoolsPayload, type?: TeamEnums) => {
    const { country, state, association } = payload;

    const getUrl = () => {
      const params = new URLSearchParams();

      if (country) params.append('country', country);
      if (state) params.append('state', state);
      if (association) params.append('association', association);

      if (params.toString()) {
        return `${apiUrlV4}school/state/?${params.toString()}`;
      }

      return `${apiUrlV4}school/getAll`;
    };

    const getSchools = () => axios
      .get(getUrl())
      .then((res) => res.data);

    return useQuery(
      {
        queryKey: ['get-schools', payload, type],
        queryFn: getSchools,
        initialData: [],
        onError: (error) => {
          snackbarService.error("Can't get schools by state. Please, try later.");
          console.error("Can't get schools by state:", error);
        },
        enabled: (!!country && !!state) || (!!country && !!state && !!association),
        refetchOnWindowFocus: false,
      },
    );
  };

  const useGetSchoolsPaginated = (payload: GetSchoolsPaginatedPayload, enabled: boolean) => {
    const getSchoolsPaginated = () => axios
      .post(`${apiUrlV4}school/get-all`, payload)
      .then((res) => {
        const resData: GetSchoolsPaginatedRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-get-schools-paginated', payload],
      queryFn: getSchoolsPaginated,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      enabled: !!enabled,
      staleTime: Infinity,
      cacheTime: 2000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    });
  };

  /**
   * Get schools by email
   * @param email
   */
  const GetSchoolsByEmail = () => {
    const getSchoolsByEmail = (email: string) => axios.get(`${apiUrlV3}school/getSchoolsByEmail/${email}`)
      .then((res) => res.data);

    return useMutation(
      getSchoolsByEmail,
      {
        onError: (error) => {
          snackbarService.error("Can't get schools. Please, try later.");
          console.error('Error in get schools by email:', error);
        },
      },
    );
  };

  const GetStateFromSchool = (schoolId) => {
    const url = `${apiUrlV4}school/state?schoolIdsArray[]=${schoolId}`;
    return useQuery(
      'query-state-from-school',
      () => axios.get(url)
        .then((res) => {
          return res.data.data[0];
        }).catch((error) => {
          console.error('Error in get state from school:', error);
          snackbarService.error("Can't get state from school. Please, try later.");
        }),
      {
        enabled: !!schoolId,
        refetchOnWindowFocus: false,
      },
    );
  };

  const GetSchoolData = () => {
    const getSchoolData = (schoolId: string) => axios
      .get(`${apiUrlV3}school/${schoolId}`)
      .then((res) => {
        return res.data;
      });

    return useMutation(getSchoolData, {
      onError: (error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try get school data by id:', error);
      },
    });
  };

  const AddSchool = () => {
    const addSchool = (payload: AddSchoolPayload) => {
      return axios.post(`${apiUrlV4}school/`, payload);
    };
    return useMutation(addSchool, {
      onError: (error) => {
        snackbarService.error('Something went wrong. Please, try later.');
        console.error('Error when try to add school:', error);
      },
    });
  };

  const EditSchool = () => {
    const editSchool = (payload: any) => {
      return axios.put(`${apiUrlV4}school/${payload._id}`, payload);
    };
    return useMutation(editSchool, {
      onError: (error) => {
        snackbarService.error("Can't save school changes. Please, try later.");
        console.error('Error in saving school details:', error);
      },
    });
  };

  const SendImportRequest = () => {
    const sendRequest = (payload: ImportRequest) => {
      return axios.post(`${apiUrlV4}school/import-request`, payload);
    };

    return useMutation(sendRequest, {
      onError: (error) => {
        snackbarService.error("Can't send import request. Please, try later.");
        console.error('Error in sending import request:', error);
      },
    });
  };

  const ReSaveSchoolLogo = () => {
    const reSaveSchoolLogo = (schoolId: string) => {
      return axios.patch(`${apiUrlV4}school/resave-logo/${schoolId}`).then((res) => res.data);
    };

    return useMutation(reSaveSchoolLogo, {
      onError: (error) => {
        // @ts-ignore
        if (error?.response.status !== 422) {
          snackbarService.error("Can't re-save school logo. Please, try later.");
          console.error('Error in re-saving school logo:', error);
        }
      },
    });
  };

  const GetSchoolIdByDeviceSerial = () => {
    const getSchoolIdByDeviceSerial = (schoolId: string) => axios
      .get(`${apiUrlV4}school/device-serial/${schoolId}`)
      .then((res) => {
        const resData: GetSchoolIdByDeviceSerialResData = res.data;

        return resData;
      });

    return useMutation(getSchoolIdByDeviceSerial, {
      onError: (error) => {
        snackbarService.error("Can't get device serial. Please, try later.");
        console.error('Error in getting device serial:', error);
      },
    });
  };

  return {
    GetSchools,
    GetSchoolsByStates,
    useGetSchools,
    useGetSchoolsPaginated,
    GetSchoolsByEmail,
    GetStateFromSchool,
    GetSchoolData,
    AddSchool,
    EditSchool,
    SendImportRequest,
    ReSaveSchoolLogo,
    GetSchoolIdByDeviceSerial,
  };
};
