import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  DeviceUpdateData,
  Game,
  GameCategory,
  GameData,
  GamesAdminData,
  GamesAdminTabType,
} from 'src/interfaces/GamesAdmin';
import api from 'src/services/api';
import {
  GamesAdminFilters,
  getDefaultGamesAdminFilters,
} from 'src/services/api/GamesAdminService';

interface Store {
  loading: boolean;
  tabsData: {
    [GamesAdminTabType.AllGames]: GamesAdminData;
    [GamesAdminTabType.GamesWNotes]: GamesAdminData;
    [GamesAdminTabType.Finalized]: Game[];
  };
  filters: GamesAdminFilters;
}

const initialState: Store = {
  loading: false,
  tabsData: {
    [GamesAdminTabType.AllGames]: null,
    [GamesAdminTabType.GamesWNotes]: null,
    [GamesAdminTabType.Finalized]: null
  },
  filters: getDefaultGamesAdminFilters(),
};

export const fetchAllGames = createAsyncThunk<
GamesAdminData,
GamesAdminFilters
>('gamesAdmin/fetchAllGames', async (filters) => {
  const res = await api.gamesAdminV2Service().getAllGamesData(filters);
  return res;
});

export const fetchGamesWithNotes = createAsyncThunk<
GamesAdminData,
GamesAdminFilters
>('gamesAdmin/fetchGamesWithNotes', async (filters) => {
  const res = await api.gamesAdminV2Service().getGamesWithNotes(filters);
  return res;
});

export const fetchFinalizedGames = createAsyncThunk<Game[], GamesAdminFilters>(
  'gamesAdmin/fetchFinalizedGames',
  async (filters) => {
    const res = await api.gamesAdminV2Service().getFinalizedGames(filters);
    return res;
  }
);

interface UpdateGameFlaggedParams {
  id: string;
  userId: string;
  flagged: boolean;
  category: GameCategory;
  tab: GamesAdminTabType;
}

export const updateGameFlagged = createAsyncThunk<
UpdateGameFlaggedParams,
UpdateGameFlaggedParams
>(
  'gamesAdmin/updateGameFlagged',
  async ({
    id, flagged, userId, category, tab
  }) => {
    await api.gamesAdminV2Service().updateGameFlagged(id, userId, flagged);
    return {
      id,
      flagged,
      category,
      userId,
      tab,
    };
  }
);

interface NukeGameParams {
  userId: string;
  gameId: string;
  category: GameCategory;
  tab: GamesAdminTabType;
}

export const nukeGame = createAsyncThunk<NukeGameParams, NukeGameParams>(
  'gamesAdmin/nukeGame',
  async ({
    userId, gameId, category, tab
  }) => {
    await api.gamesAdminV2Service().nukeGame(userId, gameId);
    return {
      userId,
      gameId,
      category,
      tab,
    };
  }
);

interface UpdateGameNotesParams {
  id: string;
  notes: string;
  category: GameCategory;
  userId: string;
  tab: GamesAdminTabType;
}

export const updateGameNotes = createAsyncThunk<
UpdateGameNotesParams,
UpdateGameNotesParams
>(
  'gamesAdmin/updateGameNotes',
  async ({
    id, notes, category, userId, tab
  }) => {
    await api.gamesAdminV2Service().updateGameNotes(id, notes, userId);
    return {
      id,
      notes,
      category,
      userId,
      tab,
    };
  }
);

interface UpdateNoAutoFinalizeParams {
  id: string;
  noAutoFinalize: boolean;
  userId: string;
  category: GameCategory;
  tab: GamesAdminTabType;
}

export const updateNoAutoFinalize = createAsyncThunk<
UpdateNoAutoFinalizeParams,
UpdateNoAutoFinalizeParams
>(
  'gamesAdmin/updateNoAutoFinalize',
  async ({
    id, userId, noAutoFinalize, category, tab
  }) => {
    await api
      .gamesAdminV2Service()
      .updateGameNoAutoFinalize(id, userId, noAutoFinalize);
    return {
      id,
      userId,
      noAutoFinalize,
      category,
      tab,
    };
  }
);

interface UpdateGameScoreParams {
  id: string;
  score: {
    home: number;
    away: number;
  };
  userId: string;
  category: GameCategory;
  tab: GamesAdminTabType;
}

export const updateGameScore = createAsyncThunk<
UpdateGameScoreParams,
UpdateGameScoreParams
>(
  'gamesAdmin/updateGameScore',
  async ({
    id, score, userId, category, tab
  }) => {
    await api
      .gamesAdminV2Service()
      .updateGameScore(id, score.home, score.away, userId);
    return {
      id,
      score,
      userId,
      category,
      tab,
    };
  }
);

interface FinalizeGameParams {
  id: string;
  score: {
    home: number;
    away: number;
  };
  gameId: string;
  userId: string;
  tab: GamesAdminTabType;
}

export const finalizeGame = createAsyncThunk<
FinalizeGameParams,
FinalizeGameParams
>('gamesAdmin/finalizeGame', async ({
  id, score, gameId, userId, tab
}) => {
  await api.gamesAdminV2Service().finalizeGame(id, score, gameId, userId);
  return {
    id,
    score,
    userId,
    gameId,
    tab,
  };
});

interface RebootDeviceParams {
  serial: string;
}

export const rebootDevice = createAsyncThunk<
RebootDeviceParams,
RebootDeviceParams
>('gamesAdmin/rebootDevice', async ({ serial }) => {
  await api.gamesAdminV2Service().rebootDevice(serial);
  return { serial };
});

const gamesAdminSlice = createSlice({
  name: 'gamesAdmin',
  initialState,
  reducers: {
    setFilters: (state, action: { payload: GamesAdminFilters }) => {
      state.filters = action.payload;
    },
    updateGameData: (state, { payload }: { payload: {
      gameId: string;
      scheduleId: string;
      gameData: GameData;
      tab: GamesAdminTabType;
    } }) => {
      const {
        gameId, scheduleId, gameData, tab
      } = payload;
      const data = state.tabsData[tab];
      if (tab === GamesAdminTabType.Finalized) {
        const game = (data as Game[])?.find((g) => g.gameId === gameId && g.id === scheduleId);
        if (game) {
          game.gameData = gameData;
        }
      } else {
        const categories = Object.values(GameCategory).filter(
          (category) => (data as GamesAdminData)?.[category]?.find((g) => g.gameId === gameId && g.id === scheduleId)
        );
        categories.forEach((category) => {
          const game = (data as GamesAdminData)[category].find(
            (g) => g.gameId === gameId && g.id === scheduleId
          );
          if (game) {
            game.gameData = gameData;
          }
        });
      }
    },
    updateDeviceData: (state, { payload }: { payload: { deviceData: DeviceUpdateData, tab: GamesAdminTabType; } }) => {
      const { deviceData, tab } = payload;
      const data = state.tabsData[tab];
      if (tab === GamesAdminTabType.Finalized) {
        const game = (data as Game[])?.find((g) => g.deviceData.serial === deviceData.serial);
        if (game) {
          game.deviceData = { ...deviceData, isLive: game.deviceData.isLive };
        }
      } else {
        const categories = Object.values(GameCategory).filter(
          (category) => (data as GamesAdminData)?.[category]?.find((g) => g.deviceData.serial === deviceData.serial)
        );
        categories.forEach((category) => {
          const game = (data as GamesAdminData)[category].find(
            (g) => g.deviceData.serial === deviceData.serial
          );
          if (game) {
            game.deviceData = { ...deviceData, isLive: game.deviceData.isLive };
          }
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllGames.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllGames.fulfilled, (state, action) => {
      state.loading = false;
      state.tabsData[GamesAdminTabType.AllGames] = action.payload;
    });
    builder.addCase(fetchAllGames.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchGamesWithNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGamesWithNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.tabsData[GamesAdminTabType.GamesWNotes] = action.payload;
    });
    builder.addCase(fetchGamesWithNotes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchFinalizedGames.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFinalizedGames.fulfilled, (state, action) => {
      state.loading = false;
      state.tabsData[GamesAdminTabType.Finalized] = action.payload;
    });
    builder.addCase(fetchFinalizedGames.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateGameFlagged.fulfilled, (state, action) => {
      const {
        id, flagged, category, tab
      } = action.payload;
      const data: Game[] = tab === GamesAdminTabType.Finalized
        ? state.tabsData[tab]
        : state.tabsData[tab][category];
      const index = data.findIndex(
        (game) => game.id === id
      );
      if (index !== -1) {
        data[index].flagged = flagged;
      }
    });

    builder.addCase(updateNoAutoFinalize.fulfilled, (state, action) => {
      const {
        id, noAutoFinalize, category, tab
      } = action.payload;
      const data = state.tabsData[tab][category] as Game[];
      const index = data.findIndex(
        (game) => game.gameId === id
      );
      if (index !== -1) {
        data[index].noAutoFinalize = noAutoFinalize;
      }
    });

    builder.addCase(nukeGame.fulfilled, (state, action) => {
      const { gameId, category, tab } = action.payload;
      state.tabsData[tab][category] = (state.tabsData[tab] as GamesAdminData)[category].filter(
        (game) => game.gameId !== gameId
      );
    });

    builder.addCase(updateGameScore.fulfilled, (state, action) => {
      const {
        id, score, category, tab
      } = action.payload;
      const data = state.tabsData[tab][category] as Game[];
      const index = data.findIndex(
        (game) => game.id === id
      );
      if (index !== -1) {
        data[index].gameData.state.away = score.away.toString();
        data[index].gameData.mappedState.away = score.away.toString();
        data[index].gameData.state.home = score.home.toString();
        data[index].gameData.mappedState.home = score.home.toString();
      }
    });

    builder.addCase(updateGameNotes.fulfilled, (state, action) => {
      const {
        id, notes, category, tab
      } = action.payload;
      const data: Game[] = tab === GamesAdminTabType.Finalized
        ? state.tabsData[tab]
        : state.tabsData[tab][category];
      const index = data.findIndex(
        (game) => game.id === id
      );
      if (index !== -1) {
        data[index].notes = notes;
      }
    });
  },
});

export const { setFilters, updateGameData, updateDeviceData } = gamesAdminSlice.actions;
export const gamesAdminReducer = gamesAdminSlice.reducer;
