import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'src/services/api';
import { Country } from 'src/services/api/CountriesService';

interface Store {
  countries: Country[];
}

const initialState: Store = {
  countries: [],
};

export const fetchCountries = createAsyncThunk<Country[]>('countries/fetchCountries', () => {
  return api.countriesService().getCountries();
});

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

export const selectCountries = createSelector(
  (store) => store.countries.countries,
  (countries: Country[]) => countries.map((country) => ({
    value: country.code,
    label: country.code,
  }))
);

export type CountryState = {
  value: string;
  label: string;
  countryCode: string,
};

export const selectStatesByCountry = createSelector(
  (store) => store.countries.countries,
  (countries: Country[]) => countries.reduce((acc, country) => {
    acc[country.code] = country.states.map((state) => ({
      value: state.code,
      label: `${state.name} (${state.code})`,
      countryCode: country.code,
    }));
    return acc;
  }, {} as Record<string, CountryState[]>)
);

export const countriesReducer = countriesSlice.reducer;
