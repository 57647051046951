import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { tokenReducer } from 'src/store/reducers/tokenReducer';
import { tutorialReducer } from 'src/store/reducers/tutorialReducer';

import { associationsReducer } from './reducers/associationsReducer';
import { countriesReducer } from './reducers/countries';
import { currentUserReducer } from './reducers/currentUserReducer';
import { deviceOrderReducer } from './reducers/deviceOrderReducer';
import devicesReducer from './reducers/devices';
import facilitiesReducer from './reducers/facilities';
import fanAppUsers from './reducers/fanappusers';
import { filtersReducer } from './reducers/filtersReducer';
import gamesReducer from './reducers/games';
import { gamesAdminReducer } from './reducers/gamesAdmin';
import { gendersReducer } from './reducers/gendersReducer';
import { gradesReducer } from './reducers/gradesReducer';
import isCustomer from './reducers/isCustomer';
import newUserRequest from './reducers/newuserrequest';
import onboarding from './reducers/onboarding';
import schoolsReducer from './reducers/schools/schools';
import shadowsData from './reducers/shadows';
import { sportsReducer } from './reducers/sportsReducer';
import superAdmin from './reducers/superadmin';
import { triggerErrorReducer } from './reducers/triggerErrorReducer';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  associations: associationsReducer,
  sports: sportsReducer,
  grades: gradesReducer,
  genders: gendersReducer,
  schools: schoolsReducer,
  facilities: facilitiesReducer,
  games: gamesReducer,
  devices: devicesReducer,
  shadows: shadowsData,
  fanappusers: fanAppUsers,
  newuserrequest: newUserRequest,
  onboarding,
  superadmin: superAdmin,
  errorTrigger: triggerErrorReducer,
  isCustomer,
  tutorialState: tutorialReducer,
  deviceOrder: deviceOrderReducer,
  token: tokenReducer,
  filters: filtersReducer,
  gamesAdmin: gamesAdminReducer,
  countries: countriesReducer,
});

export const store = configureStore({
  /* Remove this after Redux improvements */
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  reducer: rootReducer,
});

export type TypedRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TypedRootState> = useSelector;
